import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../Layout"
import Footer from "../Footer"
import Navbar from "../Navbar"
import { Heading32, Paragraph16 } from "../../styles/PageStyles"

const NewsTemplate = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))

  const { html } = data.markdownRemark
  const { title, description } = data?.markdownRemark?.frontmatter

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout>
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <StyledContainer fluid>
          <div className="heading">
            <Heading32 style={{ marginBottom: 10 }}>{title}</Heading32>
            <Paragraph16>{description}</Paragraph16>
          </div>
          <div className="body" dangerouslySetInnerHTML={{ __html: html }} />
        </StyledContainer>
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default NewsTemplate

const StyledContainer = styled(Container)`
  padding: 190px 10% 100px 10%;
  min-height: 80vh;

  .heading {
    margin-bottom: 50px;
  }

  .body {
    * {
      margin-bottom: 1rem;
      text-align: justify;
      letter-spacing: 0.06em;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .body {
      * {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 769px) {
    padding: 120px 5% 50px 5%;
  }
`

export const query = graphql`
  query NewsTemplate($id: String) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
